import { createRoot } from 'react-dom/client';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ReactQueryProvider, ThemeProvider } from '@kaytrust/react-kaytrust-client';
// import CredentialModel from './CredentialModel/CredentialModel'; TODO review after demo
import CredentialsReception from './CredentialsReception/CredentialsReception';

import './index.css';
import MenuItem from './MenuItem/MenuItem';
import InboxDetail from './InboxDetail/InboxDetail';
import DetailCampaign from './DetailCampaign/DetailCampaign';
import CreateCampaign from './CreateCampaign/CreateCampaign';
import IssuanceCredentials from './IssuanceCredentials/IssuanceCredentials';
import CreateInbox from './CreateInbox/CreateInbox';
import Home from './Home/Home';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <ReactQueryProvider>
    <ThemeProvider>
      <Router basename="/">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/credentials-reception" element={<CredentialsReception />} />
          <Route path="/create-inbox" element={<CreateInbox />} />
          <Route path="/inbox-detail" element={<InboxDetail />} />
          <Route path="/create-campaign" element={<CreateCampaign />} />
          <Route path="/issuance-credentials" element={<IssuanceCredentials />} />
          <Route path="/" element={<DetailCampaign />} />
          <Route path="/menu-item" element={<MenuItem />} />
        </Routes>
      </Router>
    </ThemeProvider>
  </ReactQueryProvider>,
);
